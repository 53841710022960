.email-not-send-page .img-wrap {
  padding-top: 65px; }
  .email-not-send-page .img-wrap svg {
    height: 82px;
    width: 114px; }

.email-not-send-page .title {
  margin: 25px 0 10px; }

.email-not-send-page .subtitle {
  margin-bottom: 30px; }
