@import 'https://fonts.googleapis.com/css?family=Muli:400,600,700,800,900|Roboto+Slab:400,700';
@font-face {
  font-family: 'Axiforma';
  src: url("/fonts/Axiforma-Regular.eot");
  src: local("Axiforma Regular"), local("Axiforma-Regular"), url("/fonts/Axiforma-Regular.woff") format("woff"), url("/fonts/Axiforma-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Axiforma';
  src: url("/fonts/Axiforma-Bold.eot");
  src: local("Axiforma Bold"), local("Axiforma-Bold"), url("/fonts/Axiforma-Bold.woff") format("woff"), url("/fonts/Axiforma-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

.link-button-wrapper {
  background-color: #f0f1f5;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0; }
  @media only screen and (min-width: 901px) {
    .link-button-wrapper {
      display: none; } }
  .link-button-wrapper .wrapper {
    padding: 12px 16px; }

.link-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  border-radius: 24px;
  background-color: #51a45b;
  font-family: "Muli", sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  text-align: center;
  color: #fff;
  transition: background linear 300ms, color linear 300ms; }
