.header-main {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200; }
  @media only screen and (max-width: 900px) {
    .header-main {
      box-shadow: inset 0 -1px 0 0 #cacbd2; } }
  @media only screen and (min-width: 901px) {
    .header-main {
      box-shadow: inset 0 -1px 0 0 #eee; } }
  .header-main .header-main-wrapper {
    position: relative;
    z-index: 200; }
    @media only screen and (max-width: 900px) {
      .header-main .header-main-wrapper {
        height: 50px; } }
    @media only screen and (min-width: 901px) {
      .header-main .header-main-wrapper {
        height: 70px; } }
  @media only screen and (min-width: 901px) {
    .header-main .img-wrap {
      float: left; } }
  .header-main .logo-icon {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -25px;
    top: 15px;
    width: 52px;
    height: 18px; }
    @media only screen and (min-width: 901px) {
      .header-main .logo-icon {
        display: none; } }
  @media only screen and (max-width: 900px) {
    .header-main .logo-link {
      display: none; } }
  .header-main .nerdify-logo {
    display: block;
    position: absolute;
    left: 0;
    top: 15px;
    width: 95px;
    height: 41px; }
  .header-main .ref-link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 16px; }
    @media only screen and (min-width: 901px) {
      .header-main .ref-link {
        display: none; } }
  .header-main .people-icon {
    width: 19px;
    height: 17px;
    stroke: #51A45B; }
  .header-main .ref-link-msg {
    font-family: "Muli", sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.25px;
    color: #51a45b;
    margin-left: 4px; }
  @media only screen and (max-width: 900px) {
    .header-main .desktop-menu {
      display: none; } }

@media only screen and (max-width: 900px) {
  .header-with-banner .main-menu-list-wrapper {
    top: 110px;
    height: calc(100vh - 109px); }
  .header-with-banner .main-menu-list {
    min-height: calc(100vh - 109px); }
  .header-with-banner + .orderlist-page .buttons-group.filters {
    top: 110px; }
  .header-with-banner + .orderlist-page .frame.orderlist-frame {
    margin-top: 169px; } }

@media only screen and (min-width: 901px) {
  .header-with-banner + .orderlist-page .frame.orderlist-frame {
    margin-top: 188px; } }

.order-step-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  margin: 94px auto 0;
  padding-bottom: 8px; }
  @media only screen and (max-width: 900px) {
    .order-step-block {
      display: none; } }

.step-info {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.3px;
  color: #4e526b; }

@media only screen and (max-width: 900px) {
  .header-main .step-info {
    position: absolute;
    right: 15px;
    top: 13px; } }

@media only screen and (min-width: 901px) {
  .header-main .step-info {
    display: none; } }

.step-back-link span {
  display: inline-block;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #24a752;
  margin-left: 9px;
  vertical-align: top; }

.step-back-link .back-arrow2 {
  width: 11px;
  height: 22px; }

@media only screen and (max-width: 900px) {
  .header-main .step-back-link {
    position: absolute;
    left: 15px;
    top: 15px; } }

@media only screen and (min-width: 901px) {
  .header-main .step-back-link {
    display: none; } }

.subscription-banner {
  background-color: #fff; }
  @media only screen and (max-width: 900px) {
    .subscription-banner {
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08); } }
  @media only screen and (min-width: 901px) {
    .subscription-banner {
      position: relative;
      box-shadow: inset 0 -1px 0 0 #eee; } }

@media only screen and (max-width: 900px) {
  .subscription-banner-close-btn {
    margin-left: auto; } }

@media only screen and (min-width: 901px) {
  .subscription-banner-close-btn {
    position: absolute;
    top: 50%;
    right: 16px;
    margin-top: -8px; } }

.subscription-banner-close-btn .close-icon {
  display: block;
  width: 16px;
  height: 16px;
  color: #797c90; }

.subscription-banner-wrapper {
  display: flex;
  height: 60px; }
  @media only screen and (max-width: 900px) {
    .subscription-banner-wrapper {
      justify-content: flex-start;
      align-items: center;
      padding: 0 16px; } }
  @media only screen and (min-width: 901px) {
    .subscription-banner-wrapper {
      justify-content: center;
      align-items: center;
      text-align: center; } }

.subscription-banner-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 0;
  border-radius: 4px;
  border: solid 2px #25a652;
  font-family: "Axiforma", sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #25a652; }
  @media only screen and (max-width: 900px) {
    .subscription-banner-btn {
      height: 34px; } }
  @media only screen and (min-width: 901px) {
    .subscription-banner-btn {
      height: 29px; } }

.subscription-banner-msg {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  color: #1d2034;
  margin-right: 16px; }

.subscription-banner-msg-highlight {
  font-weight: bold;
  color: #ff7d2b; }
  @media only screen and (max-width: 900px) {
    .subscription-banner-msg-highlight {
      display: block;
      line-height: 1.25; } }
  @media only screen and (min-width: 901px) {
    .subscription-banner-msg-highlight {
      line-height: 1.63; } }

.subscription-banner-msg2 {
  font-family: "Roboto Slab", serif;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 45px; }
  @media only screen and (max-width: 900px) {
    .subscription-banner-msg2 {
      font-size: 14px;
      line-height: 18px; } }
  @media only screen and (min-width: 901px) {
    .subscription-banner-msg2 {
      font-size: 16px; } }

.subscription-banner-msg-highlight2 {
  font-weight: bold;
  color: #721EC7;
  font-size: 18px;
  line-height: 23px; }
  @media only screen and (max-width: 900px) {
    .subscription-banner-msg-highlight2 {
      display: block;
      line-height: 23px; } }
  @media only screen and (min-width: 901px) {
    .subscription-banner-msg-highlight2 {
      line-height: 1.63; } }
