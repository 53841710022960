@media only screen and (max-width: 900px) {
  .my-info-page .frame {
    padding: 20px 15px 75px; } }

.my-info-list li:not(:first-child) {
  margin-top: 25px; }

.my-info-list .info-line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px; }

.my-info-list .item-label {
  font-size: 16px;
  color: #9496a6;
  line-height: 21px; }

.my-info-list .email-status {
  font-family: "Muli", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px; }

.my-info-list .input-item input {
  border-radius: 4px;
  background-color: #fff;
  border: solid 1px #cacbd3;
  width: 100%;
  height: 50px;
  letter-spacing: 1px;
  color: #31354f;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Muli", sans-serif;
  font-size: 17px;
  font-weight: 600; }
  .my-info-list .input-item input:-moz-placeholder {
    color: #9496a6;
    letter-spacing: 1px; }
  .my-info-list .input-item input::-moz-placeholder {
    color: #9496a6;
    letter-spacing: 1px; }
  .my-info-list .input-item input:-ms-input-placeholder {
    color: #9496a6;
    letter-spacing: 1px; }
  .my-info-list .input-item input::-webkit-input-placeholder {
    color: #9496a6;
    letter-spacing: 1px; }

.my-info-list .input-item.error input {
  border-color: #ff5d5d; }

.my-info-list .input-item.error .email-status {
  color: #ff5d5d; }

.my-info-list .input-item.saved input {
  border-color: #24a752; }

.my-info-list .input-item.saved .email-status {
  color: #24a752; }

.my-info-list .balance-item {
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: solid 1px #cacbd3; }

.my-info-list .balance-label {
  font-size: 18px;
  color: #9496a6;
  line-height: 29px; }

.my-info-list .balance-amount {
  font-family: "Muli", sans-serif;
  font-size: 23px;
  font-weight: 600;
  line-height: 29px;
  color: #4e526b; }
