.link-underline {
  text-decoration: underline; }

.subs-bnt2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 287px;
  height: 50px;
  border-radius: 8px;
  background-color: #24a652;
  font-family: "Muli", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #fff; }
  .subs-bnt2.type2 {
    box-shadow: 0 5px 9px 0 rgba(36, 166, 82, 0.3); }

.header-subscription .wrapper {
  position: relative;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 900px) {
    .header-subscription .wrapper {
      height: 116px;
      justify-content: center; } }
  @media only screen and (min-width: 901px) {
    .header-subscription .wrapper {
      height: 116px;
      justify-content: space-between; } }

@media only screen and (min-width: 901px) {
  .header-subscription.header-subscription-active .wrapper {
    justify-content: center; } }

.header-subscription .back-link {
  display: block;
  position: absolute; }
  @media only screen and (max-width: 900px) {
    .header-subscription .back-link {
      top: 28px;
      left: 28px; } }
  @media only screen and (min-width: 901px) {
    .header-subscription .back-link {
      top: 39px;
      left: -48px; } }

.header-subscription .back-icon {
  display: block;
  width: 24px;
  height: 18px; }

.header-subscription .logo {
  display: block; }

.header-subscription .prime-logo {
  width: 118px;
  height: 60px; }

.header-subscription .club-logo {
  width: 140px;
  height: 65px; }

.subscription-page {
  flex: 1;
  background-image: linear-gradient(to bottom, #f9f9fb, #fff 30%); }
  @media only screen and (max-width: 900px) {
    .subscription-page .wrapper {
      padding-left: 20px;
      padding-right: 20px; } }
  @media only screen and (min-width: 901px) {
    .subscription-page .bg-block {
      background: url("img/bg-desk3.svg") no-repeat bottom; } }
  .subscription-page .hero {
    display: flex; }
    @media only screen and (max-width: 900px) {
      .subscription-page .hero {
        margin-top: -46px;
        background: url("img/bg-top-mob.svg") no-repeat top center, url("img/bg-bottom-mob.svg") no-repeat bottom center;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center; } }
    @media only screen and (min-width: 901px) {
      .subscription-page .hero {
        margin-top: -52px;
        height: 358px;
        background: url("img/bg-desk.svg") no-repeat center;
        margin-bottom: -5px;
        justify-content: space-between;
        align-items: flex-start;
        text-align: left; } }
    @media only screen and (max-width: 900px) {
      .subscription-page .hero .title-block {
        text-align: center;
        margin: 50px 0; } }
    @media only screen and (min-width: 901px) {
      .subscription-page .hero .title-block {
        margin-top: 92px; } }
    @media only screen and (max-width: 900px) {
      .subscription-page .hero .title {
        font-size: 26px;
        line-height: 1.4;
        letter-spacing: 0.36px;
        color: #31354f; } }
    @media only screen and (min-width: 901px) {
      .subscription-page .hero .title {
        font-size: 40px;
        line-height: 1.28;
        letter-spacing: 0.83px;
        color: #31354f; } }
    .subscription-page .hero .trial-label {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 31px;
      border-radius: 15.5px;
      background-color: #ff7d2b;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.22px;
      color: #fff;
      padding: 0 14px;
      margin-bottom: 18px; }
    .subscription-page .hero .hero-prime-img {
      display: block; }
      @media only screen and (max-width: 900px) {
        .subscription-page .hero .hero-prime-img {
          height: 212px;
          padding-bottom: 20px; } }
      @media only screen and (min-width: 901px) {
        .subscription-page .hero .hero-prime-img {
          height: 296px;
          margin: 7px 20px 0 0; } }
    .subscription-page .hero .hero-basic-img {
      display: block; }
      @media only screen and (max-width: 900px) {
        .subscription-page .hero .hero-basic-img {
          height: 217px;
          padding-bottom: 30px; } }
      @media only screen and (min-width: 901px) {
        .subscription-page .hero .hero-basic-img {
          height: 296px;
          margin: 7px 20px 0 0; } }
  .subscription-page .view-plan-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .subscription-page .view-plan-block .subtitle {
      font-size: 18px;
      line-height: 1.5;
      letter-spacing: 0.24px;
      color: #31354f;
      margin: 25px 0 20px; }
  .subscription-page .subs-bnt1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 287px;
    height: 50px;
    border-radius: 8px;
    border: solid 1px #24a652;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #24a652; }
  @media only screen and (min-width: 901px) {
    .subscription-page .subs-block {
      display: grid;
      grid-template-columns: 337px 337px;
      grid-gap: 60px;
      justify-content: center;
      place-self: stretch center; } }
  .subscription-page .subs-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center; }
    .subscription-page .subs-item.type1 {
      padding-top: 50px; }
      @media only screen and (max-width: 900px) {
        .subscription-page .subs-item.type1 {
          margin-top: 40px; } }
    .subscription-page .subs-item.type2 {
      margin-top: 24px; }
  .subscription-page .subs-icon {
    display: block;
    width: 24px;
    height: 21px;
    margin-top: -50px; }
  .subscription-page .subs-item-main-title {
    font-size: 35px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 0.7px;
    text-align: center;
    padding: 0 7px;
    background-color: #fff;
    margin-top: 5px; }
    .subscription-page .subs-item-main-title.type1 {
      color: #0084ff; }
    .subscription-page .subs-item-main-title.type2 {
      color: #ff7d2b; }
  .subscription-page .subs-label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px 0 14px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.22px;
    color: #fff;
    height: 31px;
    border-radius: 15.5px;
    margin-top: 8px; }
    .subscription-page .subs-label.type1 {
      background-color: #0084ff; }
    .subscription-page .subs-label.type2 {
      background-color: #ff7d2b; }
  .subscription-page .subs-label2 {
    border-top: solid 1px #cbcbcb;
    padding: 23px 0 5px;
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: 0.24px;
    color: #31354f;
    text-align: center; }
  .subscription-page .subs-label3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.22px;
    text-align: center;
    color: rgba(28, 32, 54, 0.5);
    text-decoration: line-through;
    margin: 10px 0 23px; }
  .subscription-page .subs-item-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    padding: 0 22px 22px;
    border-radius: 12px;
    border: solid 1px #cbcbcb;
    background-color: #fff; }
    .subscription-page .subs-item-body .subscribe-btn {
      margin-top: 30px; }
  .subscription-page .subs-list {
    margin-top: 23px;
    flex: 1; }
  .subscription-page .subs-list-item:not(:first-child) {
    margin-top: 27px; }
  .subscription-page .check-icon {
    width: 22px;
    height: 22px; }
  .subscription-page .subs-item-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    color: #1c2036;
    margin: 4px 0; }
  .subscription-page .subs-item-subtitle {
    font-family: "Muli", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #1c2036; }
  .subscription-page .agree-line1 {
    font-family: "Muli", sans-serif;
    font-size: 12px;
    line-height: 1.33;
    text-align: center;
    color: #4e526b; }
    @media only screen and (max-width: 900px) {
      .subscription-page .agree-line1 {
        margin-top: 28px; } }
    @media only screen and (min-width: 901px) {
      .subscription-page .agree-line1 {
        margin-top: 32px; } }
  .subscription-page .agree-line2 {
    font-family: "Muli", sans-serif;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    color: #797c91; }
    @media only screen and (max-width: 900px) {
      .subscription-page .agree-line2 {
        margin-top: 28px; } }
    @media only screen and (min-width: 901px) {
      .subscription-page .agree-line2 {
        margin-top: 32px; } }
  .subscription-page .contact-line {
    font-family: "Muli", sans-serif;
    text-align: center;
    color: #1c2036; }
    @media only screen and (max-width: 900px) {
      .subscription-page .contact-line {
        font-size: 16px;
        line-height: 1.5;
        margin-top: 40px; } }
    @media only screen and (min-width: 901px) {
      .subscription-page .contact-line {
        font-size: 14px;
        line-height: 1.71;
        margin-top: 28px; } }
  .subscription-page .contact-link {
    color: #24a652;
    text-decoration: underline; }
  .subscription-page .subs-item2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
    padding: 16px;
    border-radius: 12px;
    background-color: #f0f1f5; }
    @media only screen and (min-width: 901px) {
      .subscription-page .subs-item2 {
        width: 342px; } }
  .subscription-page .subs-item2-title {
    font-size: 19px;
    line-height: 1.5;
    letter-spacing: 0.27px;
    text-align: center;
    color: #4e526b; }
  .subscription-page .subs-item2-status {
    font-family: "Muli", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #4e526b;
    margin: 18px 0 16px; }
    .subscription-page .subs-item2-status .active {
      font-weight: bold;
      color: #24a652; }
    .subscription-page .subs-item2-status .failed {
      font-weight: bold;
      color: #fa1d47; }
  .subscription-page .subs-item2-subtitle {
    font-family: "Muli", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #4e526b;
    margin-top: 20px; }
  .subscription-page .subs-item2-link {
    color: #24a652;
    text-decoration: underline; }

.premium-addons {
  flex: 1;
  background-color: #fff; }
  @media only screen and (max-width: 900px) {
    .premium-addons {
      padding-top: 50px; } }
  @media only screen and (min-width: 901px) {
    .premium-addons {
      padding-top: 70px; } }
  @media only screen and (max-width: 900px) {
    .premium-addons .wrapper {
      padding-left: 20px;
      padding-right: 20px; } }
  .premium-addons .title1 {
    text-align: center;
    color: #31354F; }
    @media only screen and (max-width: 900px) {
      .premium-addons .title1 {
        font-size: 28px;
        line-height: 34px;
        margin: 28px 0 48px; } }
    @media only screen and (min-width: 901px) {
      .premium-addons .title1 {
        font-size: 36px;
        line-height: 43px;
        margin: 35px 0 54px; } }
    .premium-addons .title1 strong {
      font-weight: 700;
      color: #721EC7; }
  .premium-addons .title2 {
    text-align: center;
    color: #31354F;
    font-size: 24px;
    line-height: 32px;
    margin: 17px 0 24px; }
  .premium-addons .subs-list {
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    padding: 44px 20px 20px;
    display: block;
    flex-direction: column;
    width: 100%;
    position: relative; }
  .premium-addons .subs-list2 {
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    padding: 20px;
    display: block;
    flex-direction: column;
    width: 100%; }
  .premium-addons .label {
    position: absolute;
    top: -22px;
    left: 50%;
    height: 45px;
    width: 118px;
    margin-left: -59px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EEEEEE;
    border-left: 7px solid #FFF;
    border-right: 7px solid #FFF;
    border-radius: 100px;
    font-size: 18px;
    color: #1C2036; }
  .premium-addons .label2 {
    border-top: 1px solid #E1E1E1;
    margin-top: 20px;
    padding-top: 20px;
    font-family: "Axiforma", sans-serif;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5); }
  .premium-addons .subs-list-item {
    display: flex;
    justify-content: flex-start; }
    .premium-addons .subs-list-item:not(:first-child) {
      margin-top: 28px; }
  .premium-addons .check-icon {
    display: block;
    width: 18px;
    height: 18px;
    flex-shrink: 0; }
  .premium-addons .subs-item-msg {
    margin-left: 16px; }
  .premium-addons .subs-item-title {
    font-family: "Axiforma", sans-serif;
    font-size: 18px;
    line-height: 21px;
    color: #000; }
    .premium-addons .subs-item-title strong {
      font-weight: 700; }
  .premium-addons .subs-item-subtitle {
    font-family: "Axiforma", sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 6px; }
  .premium-addons .subs-bnt3 {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 206px;
    height: 51px;
    background: #24A652;
    box-shadow: 0 4px 8px rgba(36, 166, 82, 0.1);
    border-radius: 8px;
    font-family: "Muli", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #FFF;
    margin: 16px auto 0; }
  .premium-addons .agree-line {
    font-family: "Muli", sans-serif;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6); }
  .premium-addons .agree-line3 {
    margin-top: 28px; }
  .premium-addons .agree-line4 {
    margin-top: 8px; }
  .premium-addons .agree-line5 {
    margin-top: 16px; }
  .premium-addons .subs-item3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
    padding: 16px;
    border-radius: 12px;
    background-color: #f0f1f5; }
  .premium-addons .subs-item3-title {
    font-size: 19px;
    line-height: 1.5;
    letter-spacing: 0.27px;
    text-align: center;
    color: #4e526b; }
  .premium-addons .subs-item3-status {
    font-family: "Muli", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #4e526b;
    margin: 18px 0 16px; }
    .premium-addons .subs-item3-status .active {
      font-weight: bold;
      color: #24a652; }
    .premium-addons .subs-item3-status .failed {
      font-weight: bold;
      color: #fa1d47; }
  .premium-addons .subs-item3-subtitle {
    font-family: "Muli", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #4e526b;
    margin-top: 20px; }
  .premium-addons .subs-item3-link {
    color: #24a652;
    text-decoration: underline; }

.active-subs-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  @media only screen and (max-width: 900px) {
    .active-subs-block {
      margin-top: -54px;
      background: url("./img/bg-active-top-mob.svg") no-repeat top center; } }
  @media only screen and (min-width: 901px) {
    .active-subs-block {
      margin-top: -38px;
      background: url("./img/bg-active-top-desk.svg") no-repeat top center, url("./img/bg-active-bottom-desk.svg") no-repeat bottom center; } }
  .active-subs-block .active-subs-title {
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: 0.7px;
    color: #1c2036;
    text-align: center; }
    @media only screen and (max-width: 900px) {
      .active-subs-block .active-subs-title {
        margin-top: 40px; } }
    @media only screen and (min-width: 901px) {
      .active-subs-block .active-subs-title {
        margin-top: 86px; } }
  .active-subs-block .subs-name-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 40px; }
  .active-subs-block .subs-name-title {
    font-size: 24px;
    line-height: 1.4;
    letter-spacing: 0.32px;
    color: #4e526b;
    text-align: center;
    margin-bottom: 16px; }
  .active-subs-block .subs-name-input {
    box-sizing: border-box;
    height: 50px;
    width: 100%;
    padding: 0 16px;
    border-radius: 8px;
    border: solid 1px #d5d6de;
    background-color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: 1.5;
    color: #4e526b; }
  .active-subs-block .submit-nickname-btn {
    margin-top: 16px; }

.pastdue-info {
  border: solid 2px #ff7d2b;
  border-radius: 16px;
  max-width: 335px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px; }

.pastdue-info_type1 {
  background-color: #f9f9fb;
  margin-top: 86px; }
  .pastdue-info_type1 .pastdue-info-title {
    background: #f9f9fb; }

.pastdue-info_type2 {
  background-color: #fff;
  margin-top: 40px;
  margin-bottom: 24px; }
  .pastdue-info_type2 .pastdue-info-title {
    background-color: #fff; }

.pastdue-info-title-wrapper {
  text-align: center;
  margin-top: -38px; }

.pastdue-info-title {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.7px;
  text-align: center;
  color: #ff7d2b;
  padding: 0 14px;
  border-radius: 7px; }

.pastdue-info-icon {
  display: block;
  width: 36.7px;
  height: 32px;
  margin-top: 16px; }

.pastdue-info-msg {
  font-family: "Muli", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #1c2036;
  margin-top: 10px; }
  .pastdue-info-msg strong {
    font-weight: 700;
    color: #ff7d2b; }

.pastdue-info-msg2 {
  font-family: "Muli", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #a3a3a3; }
  .pastdue-info-msg2:before {
    content: '';
    display: block;
    width: 80px;
    height: 0;
    margin: 16px auto;
    border-top: solid 1px #979797; }

.pastdue-info-msg-link {
  display: block;
  text-decoration: underline;
  color: #24a652; }

/*
@include respond-to(min) {
}
@include respond-to(max) {
}
*/
