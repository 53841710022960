.progress-tracker-page {
  text-align: center; }
  .progress-tracker-page .img-wrap {
    padding-top: 90px; }
    .progress-tracker-page .img-wrap svg {
      width: 133.1px;
      height: 110px; }
  .progress-tracker-page .title {
    margin: 25px 0 10px; }
  .progress-tracker-page .subtitle {
    margin: 0 auto 30px;
    width: 280px; }
