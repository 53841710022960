@import 'https://fonts.googleapis.com/css?family=Muli:400,600,700,800,900|Roboto+Slab:400,700';
@font-face {
  font-family: 'Axiforma';
  src: url("/fonts/Axiforma-Regular.eot");
  src: local("Axiforma Regular"), local("Axiforma-Regular"), url("/fonts/Axiforma-Regular.woff") format("woff"), url("/fonts/Axiforma-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Axiforma';
  src: url("/fonts/Axiforma-Bold.eot");
  src: local("Axiforma Bold"), local("Axiforma-Bold"), url("/fonts/Axiforma-Bold.woff") format("woff"), url("/fonts/Axiforma-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

.title_share {
  font-family: "Roboto Slab", serif;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #ffa204;
  margin-top: 35px; }

.ref-msg-block {
  border-radius: 3px;
  border: solid 1px #cacbd3;
  position: relative;
  padding: 25px 20px 13px;
  text-align: center;
  margin-top: 23px; }

.ref-msg-block__title {
  position: absolute;
  top: -12px;
  left: 0;
  width: 100%; }

.ref-msg-block__title_msg {
  display: inline-block;
  background-color: #fff;
  padding: 0 7px;
  font-family: "Muli", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #9496a6; }

.ref-msg-block__info {
  font-family: "Muli", sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.35;
  color: #4e526b; }

.ref-msg-block__link {
  display: inline-block;
  margin-top: 10px;
  font-family: "Muli", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #1a99ff; }

.ref-social-block {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 900px) {
    .ref-social-block {
      flex-wrap: wrap; } }
  @media only screen and (min-width: 901px) {
    .ref-social-block {
      flex-wrap: nowrap;
      margin-top: 10px; } }

@media only screen and (max-width: 900px) {
  .ref-social-block__item {
    margin-top: 10px;
    width: 82px; } }

@media only screen and (min-width: 901px) {
  .ref-social-block__item {
    width: 138px; } }

@media only screen and (max-width: 900px) {
  .ref-social-block__item_link-copy {
    width: 100%; } }

.ref-social-block__link {
  border-radius: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  cursor: pointer; }

.ref-social-block__link_copy {
  background-color: #24a652;
  font-family: "Roboto Slab", serif;
  color: #fff; }
  @media only screen and (max-width: 900px) {
    .ref-social-block__link_copy {
      font-size: 20px;
      font-weight: bold; } }
  @media only screen and (min-width: 901px) {
    .ref-social-block__link_copy {
      font-size: 16px; } }

.ref-social-block__link_email {
  background-color: #777a95; }

.ref-social-block__link_fb {
  background-color: #4266b2; }

.ref-social-block__link_twitter {
  background-color: #1da1f2; }

.ref-social-block__link_msg {
  background-color: #0084ff; }

.ref-social-block__link_sms {
  background-color: #777a95; }

.ref-social-block__icon {
  display: inline-block;
  fill: #fff; }
  .ref-social-block__icon.email-icon2 {
    width: 25.3px;
    height: 17.4px; }
  .ref-social-block__icon.fb-icon2 {
    width: 11px;
    height: 22px; }
  .ref-social-block__icon.twitter-icon {
    width: 24.6px;
    height: 20px; }
  .ref-social-block__icon.msg-icon2 {
    width: 26.1px;
    height: 26.3px; }
  .ref-social-block__icon.sms-icon {
    width: 31.6px;
    height: 27.7px; }
