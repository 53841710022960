.select-time {
  margin-top: 20px; }

.select-block.time-item {
  width: 50%;
  display: inline-block;
  border-radius: 0; }
  .select-block.time-item.left {
    float: left; }
    .select-block.time-item.left .select-substrate {
      border-radius: 4px 0 0 4px; }
  .select-block.time-item.right {
    float: right; }
    .select-block.time-item.right .select-substrate {
      border-radius: 0 4px 4px 0;
      border-left: none; }
