@media only screen and (max-width: 900px) {
  .header-login {
    height: 75px; } }

@media only screen and (min-width: 901px) {
  .header-login {
    background-color: #fff;
    box-shadow: inset 0 -1px 0 0 #eee;
    height: 95px; } }

.header-login.with-back-link .back-link {
  display: block; }

.header-login .wrapper {
  position: relative; }

.header-login .back-link {
  display: none;
  position: absolute;
  left: 20px;
  top: 35px; }

.header-login .back-arrow {
  width: 31px;
  height: 25px; }

.header-login .nerdify-logo {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-75px);
  width: 127px;
  height: 55px; }
