.faq_2 {
  text-align: center;
  margin-top: 40px; }
  @media only screen and (max-width: 900px) {
    .faq_2 {
      padding: 0 15px; } }
  .faq_2 .h6 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.44;
    color: #1c2036;
    font-family: "Muli", sans-serif; }
    @media only screen and (max-width: 900px) {
      .faq_2 .h6 {
        font-size: 18px;
        margin-bottom: 20px; } }
  .faq_2 .h3 {
    font-size: 32px; }
    @media only screen and (max-width: 900px) {
      .faq_2 .h3 {
        font-size: 25px; } }
  .faq_2 .p {
    font-family: "Roboto Slab", serif;
    font-size: 16px;
    line-height: 1.5;
    color: #4e526b;
    margin-bottom: 28px; }
    @media only screen and (max-width: 900px) {
      .faq_2 .p {
        font-size: 18px; } }
  .faq_2 .accordion__icon {
    margin-right: 20px;
    margin-top: 1px; }
    .faq_2 .accordion__icon svg {
      width: 22px;
      height: 22px; }
  .faq_2 .plus {
    display: block; }
  .faq_2 .minus {
    display: none; }
  .faq_2 .accordion__item_active .minus {
    display: block; }
  .faq_2 .accordion__item_active .plus {
    display: none; }
  .faq_2 .accordion__item-holder {
    width: 360px; }
    @media only screen and (max-width: 900px) {
      .faq_2 .accordion__item-holder {
        width: 100%; } }
    .faq_2 .accordion__item-holder:first-child {
      margin-right: 40px; }
      @media only screen and (max-width: 900px) {
        .faq_2 .accordion__item-holder:first-child {
          margin-right: 0; } }
  .faq_2 .accordion__toggle {
    display: flex;
    align-items: flex-start; }
  .faq_2 .accordion {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    @media only screen and (max-width: 900px) {
      .faq_2 .accordion {
        margin-top: 33px; } }
  .faq_2 .accordion__item {
    margin-bottom: 28px; }
    @media only screen and (max-width: 900px) {
      .faq_2 .accordion__item {
        margin-bottom: 10px; } }
  .faq_2 .accordion__body {
    margin-top: 12px;
    text-align: left;
    padding-left: 42px; }
  .faq_2 .accordion__heading {
    text-align: left; }
  .faq_2 .link:hover {
    text-decoration: none; }
  .faq_2.faq_2_1 {
    margin-bottom: 72px; }
    .faq_2.faq_2_1 .h3 {
      font-size: 29px;
      line-height: normal;
      margin-top: 60px;
      color: #1c2036; }
    .faq_2.faq_2_1 .accordion__item-holder:first-child {
      width: 360px; }
    .faq_2.faq_2_1 .accordion__item-holder:first-child + .accordion__item-holder {
      width: 400px; }
