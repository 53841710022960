@import 'https://fonts.googleapis.com/css?family=Muli:400,600,700,800,900|Roboto+Slab:400,700';
@font-face {
  font-family: 'Axiforma';
  src: url("/fonts/Axiforma-Regular.eot");
  src: local("Axiforma Regular"), local("Axiforma-Regular"), url("/fonts/Axiforma-Regular.woff") format("woff"), url("/fonts/Axiforma-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Axiforma';
  src: url("/fonts/Axiforma-Bold.eot");
  src: local("Axiforma Bold"), local("Axiforma-Bold"), url("/fonts/Axiforma-Bold.woff") format("woff"), url("/fonts/Axiforma-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@media only screen and (max-width: 900px) {
  .order-select-job-type-wrapper {
    background: #f0f1f5;
    padding-bottom: 10px; } }

.order-select-job-type {
  background: #fff;
  width: 100%; }
  .order-select-job-type h2 {
    padding-bottom: 16px; }
    @media only screen and (max-width: 900px) {
      .order-select-job-type h2 {
        padding-top: 16px; } }

.order-select-job-type-list {
  position: relative; }

.order-select-job-type-list-item {
  padding: 17px 15px;
  border-top: 1px solid #ededf0;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.3px;
  color: #24a752;
  position: relative;
  cursor: pointer;
  transition: background 300ms linear; }
  .order-select-job-type-list-item:hover {
    background: #f0f8f0; }
  .order-select-job-type-list-item.selected {
    background: #f0f8f0; }
    .order-select-job-type-list-item.selected div.check-empty {
      opacity: 0; }
    .order-select-job-type-list-item.selected div.radio-check {
      opacity: 1; }
  .order-select-job-type-list-item div {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    transition: opacity 300ms linear; }
    .order-select-job-type-list-item div.check-empty {
      opacity: 1; }
    .order-select-job-type-list-item div.radio-check {
      opacity: 0; }
    .order-select-job-type-list-item div svg {
      width: 100%;
      height: 100%; }
