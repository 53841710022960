@media only screen and (max-width: 900px) {
  .back-to-top-button {
    display: none; } }

@media only screen and (min-width: 901px) {
  .back-to-top-button {
    position: fixed;
    bottom: -35px;
    left: 50%;
    margin-left: 420px;
    -webkit-transition: bottom 0.3s linear;
      -webkit-transition-transition: bottom .3s linear; } }

@media only screen and (min-width: 901px) {
  .back-to-top-button_active {
    bottom: 30%; } }

@media only screen and (min-width: 901px) {
  .back-to-top-button__svg {
    display: block;
    width: 35px;
    height: 35px; } }
