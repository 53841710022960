@import 'https://fonts.googleapis.com/css?family=Muli:400,600,700,800,900|Roboto+Slab:400,700';
@font-face {
  font-family: 'Axiforma';
  src: url("/fonts/Axiforma-Regular.eot");
  src: local("Axiforma Regular"), local("Axiforma-Regular"), url("/fonts/Axiforma-Regular.woff") format("woff"), url("/fonts/Axiforma-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Axiforma';
  src: url("/fonts/Axiforma-Bold.eot");
  src: local("Axiforma Bold"), local("Axiforma-Bold"), url("/fonts/Axiforma-Bold.woff") format("woff"), url("/fonts/Axiforma-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

.button {
  background: #24a652;
  width: 345px;
  margin-top: 12px;
  font-family: "Roboto Slab", serif;
  font-size: 23px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  transition: background linear 300ms, color linear 300ms; }
  .button.measure-item {
    background: #fff;
    width: 50%;
    border: 1px solid #24a752;
    font-size: 20px;
    line-height: 45px;
    letter-spacing: 0.3px;
    color: #24a652;
    left: auto;
    transform: none;
    display: inline-block; }
    .button.measure-item:first-child {
      border-radius: 4px 0 0 4px; }
    .button.measure-item:last-child {
      border-radius: 0 4px 4px 0; }
    .button.measure-item.active {
      background: #24a752;
      color: #fff; }
