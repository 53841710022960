.frame_ref {
  background-color: #fff; }
  @media only screen and (max-width: 900px) {
    .frame_ref {
      padding: 15px 15px 50px; } }
  @media only screen and (min-width: 901px) {
    .frame_ref {
      margin: 40px auto 0;
      box-sizing: border-box;
      width: 700px;
      border-radius: 8px;
      border: solid 1px #eee;
      padding: 35px 50px 50px; } }

.title_ref {
  line-height: 1.2; }

.subtitle_ref {
  font-size: 18px;
  text-align: center;
  color: #4e526b;
  margin-top: 18px; }

.img-wrap_ref {
  width: 150px;
  height: 125px; }
  @media only screen and (max-width: 900px) {
    .img-wrap_ref {
      margin: 29px auto; } }
  @media only screen and (min-width: 901px) {
    .img-wrap_ref {
      margin: 35px auto 36px; } }

.ref-cabinet-img {
  width: 150px;
  height: 125px; }

.counter {
  box-shadow: inset 0 1px 0 0 #eaeaed, inset 0 -1px 0 0 #eaeaed;
  padding: 13px 0 17px;
  display: table;
  width: 100%; }

.counter__item {
  padding: 8px 22px 14px;
  text-align: center;
  display: table-cell; }
  .counter__item:not(:first-child) {
    border-left: solid 1px #eaeaed; }

.counter__item_number {
  font-size: 28px;
  font-weight: bold;
  color: #4e526b; }

.counter__item_info {
  font-family: "Muli", sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.35;
  color: #6c6f84;
  text-transform: uppercase;
  margin-top: 3px; }

.hiw__title {
  font-size: 30px;
  text-align: center;
  color: #4e526b;
  margin-top: 50px; }

@media only screen and (max-width: 900px) {
  .hiw__list {
    margin-top: 27px; } }

@media only screen and (min-width: 901px) {
  .hiw__list {
    margin-top: 25px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between; } }

.hiw__list-item {
  position: relative; }
  @media only screen and (max-width: 900px) {
    .hiw__list-item {
      padding-left: 85px; } }
  @media only screen and (min-width: 901px) {
    .hiw__list-item {
      width: 170px;
      text-align: center; } }
  @media only screen and (max-width: 900px) {
    .hiw__list-item:not(:first-child) {
      margin-top: 30px; } }
  @media only screen and (min-width: 901px) {
    .hiw__list-item:not(:first-child):after {
      content: '';
      border-left: 7px solid #cacbd3;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      position: absolute;
      top: 52px;
      left: -22px; } }

.hiw__list-item-step {
  font-family: "Muli", sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #cacbd3;
  text-transform: uppercase; }
  @media only screen and (min-width: 901px) {
    .hiw__list-item-step {
      margin-bottom: 15px; } }

.hiw__list-item-icon {
  height: 60px; }
  @media only screen and (max-width: 900px) {
    .hiw__list-item-icon {
      position: absolute;
      left: 3px;
      top: 8px; } }
  .hiw__list-item-icon.hiw-img1 {
    width: 61.1px; }
  .hiw__list-item-icon.hiw-img2 {
    width: 60px; }
  .hiw__list-item-icon.hiw-img3 {
    width: 63.2px; }

.hiw__list-item-msg {
  font-family: "Muli", sans-serif;
  color: #4e526b; }
  @media only screen and (max-width: 900px) {
    .hiw__list-item-msg {
      margin-top: 3px;
      font-size: 18px;
      line-height: 1.44; } }
  @media only screen and (min-width: 901px) {
    .hiw__list-item-msg {
      margin-top: 18px;
      font-size: 15px;
      font-weight: 600;
      line-height: 1.4; } }

.hiw__list-item-msg-highlight {
  font-weight: 800; }

.ref-users-block {
  border-radius: 5px;
  background-color: #fbfbfc; }
  @media only screen and (max-width: 900px) {
    .ref-users-block {
      padding: 20px 20px 10px;
      margin-top: 70px; } }
  @media only screen and (min-width: 901px) {
    .ref-users-block {
      padding: 25px 35px 15px;
      margin-top: 50px; } }

.ref-users-title {
  font-size: 22px;
  text-align: center;
  color: #4e526b; }
  @media only screen and (max-width: 900px) {
    .ref-users-title {
      margin-bottom: 20px; } }
  @media only screen and (min-width: 901px) {
    .ref-users-title {
      font-size: 22px;
      text-align: center;
      color: #4e526b;
      margin-bottom: 25px; } }

.ref-users-table {
  width: 100%;
  table-layout: fixed; }

.ref-users-table__header {
  border-top: solid 1px #cacbd3;
  border-bottom: solid 1px #cacbd3;
  padding: 15px 0;
  text-align: center;
  font-family: "Muli", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #4e526b;
  white-space: nowrap; }
  @media only screen and (max-width: 900px) {
    .ref-users-table__header.ref-users-table__col3 {
      width: 110px; } }

.ref-users-table__cell {
  padding: 13px 0;
  text-align: center;
  font-family: "Muli", sans-serif;
  font-weight: bold;
  color: #4e526b; }
  @media only screen and (max-width: 900px) {
    .ref-users-table__cell {
      font-size: 16px; } }
  @media only screen and (min-width: 901px) {
    .ref-users-table__cell {
      font-size: 14px; } }

@media only screen and (max-width: 900px) {
  .ref-users-table__col1 {
    width: 35px; } }

@media only screen and (min-width: 901px) {
  .ref-users-table__col1 {
    width: 50px; } }

@media only screen and (max-width: 900px) {
  .ref-users-table__col2 {
    text-align: left;
    padding-left: 30px; } }

@media only screen and (max-width: 900px) {
  .ref-users-table__col3 {
    width: 50px;
    text-align: left; } }

@media only screen and (min-width: 901px) {
  .ref-users-table__col3 {
    width: 120px; } }

.ref-users-table-highlight-wrapper {
  background-color: #efeff4; }
  @media only screen and (max-width: 900px) {
    .ref-users-table-highlight-wrapper {
      margin: 0 -20px;
      padding-left: 20px;
      padding-right: 20px; } }
  @media only screen and (min-width: 901px) {
    .ref-users-table-highlight-wrapper {
      margin: 0 -12px;
      border-radius: 3px;
      padding-left: 12px;
      padding-right: 12px; } }

.ref-users-table__cell_highlighted {
  font-family: "Muli", sans-serif;
  font-size: 16px;
  font-weight: 800;
  color: #24a652;
  padding-top: 10px;
  padding-bottom: 10px; }
