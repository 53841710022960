.cabinet-basic-empty-page .img-wrap {
  padding-top: 60px; }
  .cabinet-basic-empty-page .img-wrap svg {
    width: 179px;
    height: 110px; }

.cabinet-basic-empty-page .title {
  margin: 25px 0 10px; }

.cabinet-basic-empty-page .subtitle {
  margin-bottom: 30px; }
