@import 'https://fonts.googleapis.com/css?family=Muli:400,600,700,800,900|Roboto+Slab:400,700';
@font-face {
  font-family: 'Axiforma';
  src: url("/fonts/Axiforma-Regular.eot");
  src: local("Axiforma Regular"), local("Axiforma-Regular"), url("/fonts/Axiforma-Regular.woff") format("woff"), url("/fonts/Axiforma-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Axiforma';
  src: url("/fonts/Axiforma-Bold.eot");
  src: local("Axiforma Bold"), local("Axiforma-Bold"), url("/fonts/Axiforma-Bold.woff") format("woff"), url("/fonts/Axiforma-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@media only screen and (max-width: 900px) {
  .orderlist-page {
    background-color: #f0f1f5; } }

.frame.orderlist-frame {
  overflow: hidden;
  font-family: "Muli", sans-serif; }
  @media only screen and (max-width: 900px) {
    .frame.orderlist-frame {
      padding: 0;
      margin: 109px  0 72px;
      background: transparent; } }
  @media only screen and (min-width: 901px) {
    .frame.orderlist-frame {
      width: 380px;
      border: none;
      border-radius: 12px;
      background-color: #fff;
      margin-top: 128px;
      padding: 19px 18px 19px 19px; } }

.orderlist-empty {
  text-align: center;
  color: #9496a6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 900px) {
    .orderlist-empty {
      border-radius: 12px;
      box-shadow: 0 4px 11px 0 #d5d6dd;
      background-color: #ffffff;
      margin: 16px;
      height: calc(100vh - 212px); } }
  @media only screen and (min-width: 901px) {
    .orderlist-empty {
      min-height: 330px;
      padding-top: 50px; } }
  .orderlist-empty .orderlist-empty__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: -0.22px; }
    @media only screen and (max-width: 900px) {
      .orderlist-empty .orderlist-empty__title {
        margin-bottom: 16px; } }
    @media only screen and (min-width: 901px) {
      .orderlist-empty .orderlist-empty__title {
        margin-bottom: 36px; } }
    .orderlist-empty .orderlist-empty__title span {
      text-transform: capitalize; }
  .orderlist-empty .orderlist-empty__desc {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.21px;
    max-width: 313px; }
    @media only screen and (min-width: 901px) {
      .orderlist-empty .orderlist-empty__desc {
        margin-bottom: 10px; } }
  .orderlist-empty .icon-ill {
    width: 48px;
    height: 60px;
    margin-bottom: 12px; }
  .orderlist-empty .orderlist__link {
    width: 140px;
    padding: 8px 0;
    border-radius: 24px;
    background-color: #24a652;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    text-align: center;
    color: #ffffff;
    margin-bottom: 130px; }

.orderlist-item {
  border-radius: 12px;
  background-color: #fff;
  padding: 13px 16px 20px; }
  @media only screen and (max-width: 900px) {
    .orderlist-item {
      margin: 16px;
      box-shadow: 0 4px 11px 0 #d5d6dd; } }
  @media only screen and (min-width: 901px) {
    .orderlist-item {
      margin: 16px 0;
      box-shadow: 0 2px 4px 0 rgba(78, 82, 107, 0.07);
      border: solid 1px #e3e3e3; } }
  .orderlist-item .datetime-label {
    font-size: 14px;
    font-weight: 800;
    white-space: nowrap;
    color: #797c90; }
    .orderlist-item .datetime-label.highlight {
      color: #51a45b; }
  .orderlist-item .order-datetime {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.36px;
    color: #1d2034;
    white-space: nowrap; }
  .orderlist-item .order-number-wrap {
    position: relative; }
    .orderlist-item .order-number-wrap .order-number-copy {
      position: absolute;
      right: 0;
      background-color: #f0f1f5;
      color: #4f5269;
      padding: 1px 10px 2px;
      border-radius: 5px;
      top: 22px;
      opacity: 0;
      transition: opacity .5s; }
      .orderlist-item .order-number-wrap .order-number-copy.active {
        opacity: 1; }
  .orderlist-item .order-number {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    letter-spacing: -0.3px;
    color: #32354d; }
  .orderlist-item .order-type {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: 1px;
    color: #797c90;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 15px; }
  .orderlist-item .order-title {
    font-size: 20px;
    font-weight: 800;
    line-height: 1.4;
    color: #1d2034;
    padding-top: 11px;
    margin-top: 12px;
    border-top: solid 1px rgba(151, 151, 151, 0.2);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden; }
  .orderlist-item .order-size {
    margin-top: 12px;
    border-top: solid 1px rgba(151, 151, 151, 0.2);
    padding: 15px 0 14px; }
  .orderlist-item .order-size-list {
    margin: -6px -10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap; }
  .orderlist-item .order-size-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 6px 10px; }
    .orderlist-item .order-size-item .svg-icon {
      width: 20px;
      height: 20px;
      fill: #9496A5; }
  .orderlist-item .order-size-msg {
    margin-left: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: -0.21px;
    color: #797c90; }
  .orderlist-item .order-services {
    border-top: solid 1px rgba(151, 151, 151, 0.2);
    padding-top: 15px; }
  .orderlist-item .order-service-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px; }
    .orderlist-item .order-service-link .link-msg {
      margin-left: 6px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.15px;
      color: #4ea558; }
    .orderlist-item .order-service-link .plus-round-icon2 {
      display: block;
      width: 18px;
      height: 18px;
      color: #24a652; }
  .orderlist-item .order-service-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -4px 8px; }
  .orderlist-item .order-service-item {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    height: 24px;
    border-radius: 12px;
    margin: 0 4px 8px; }
    .orderlist-item .order-service-item .svg-icon {
      width: 14px;
      height: 14px; }
    .orderlist-item .order-service-item .service-title {
      margin-left: 8px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.35px; }
    .orderlist-item .order-service-item.top-nerd {
      order: 1; }
    .orderlist-item .order-service-item.plagiarism-report {
      order: 2; }
    .orderlist-item .order-service-item.draft {
      order: 3; }
    .orderlist-item .order-service-item.prioritize-my-task {
      order: 4; }
    .orderlist-item .order-service-item.active {
      background-color: #f0f1f5;
      padding: 0 10px 0 7px; }
      .orderlist-item .order-service-item.active .svg-icon {
        fill: #4ea558; }
      .orderlist-item .order-service-item.active .service-title {
        color: #32354d; }
    .orderlist-item .order-service-item.added {
      border: solid 1px #e4e5eb;
      padding: 0 8px 0 7px; }
      .orderlist-item .order-service-item.added .svg-icon {
        fill: #9496a6; }
      .orderlist-item .order-service-item.added .service-title {
        color: #797c91; }
    .orderlist-item .order-service-item.refunded {
      background-color: #fee3e9;
      padding: 0 8px 0 7px; }
      .orderlist-item .order-service-item.refunded .svg-icon {
        fill: #ed6f78; }
      .orderlist-item .order-service-item.refunded .service-title {
        color: #9496a5;
        text-decoration: line-through; }
      .orderlist-item .order-service-item.refunded .refunded-service-label {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 6.5px;
        background-color: #ed6f78;
        font-size: 10px;
        font-weight: bold;
        line-height: 13px;
        letter-spacing: 0.5px;
        color: #fff;
        padding: 0 2px 0 4px;
        margin-left: 6px;
        text-transform: uppercase; }
  .orderlist-item .order-price-block {
    display: inline-flex;
    justify-content: center; }
  .orderlist-item .order-price {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.45px;
    color: #4f5269; }
    .orderlist-item .order-price.refunded {
      text-decoration: line-through; }
  .orderlist-item .order-refunded {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.32px;
    color: rgba(229, 59, 77, 0.9);
    text-decoration: line-through;
    vertical-align: super;
    margin-left: 5px; }
  .orderlist-item .refunded-label {
    margin-top: 7px;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.15px;
    color: #e53b4d; }
  .orderlist-item .rate-task-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 208px;
    height: 46px;
    border-radius: 8px;
    border: solid 1px #51a45b;
    background-color: #51a45b; }
  .orderlist-item .star-empty-full-icon {
    width: 18px;
    height: 16px;
    color: #fff;
    fill: none; }
  .orderlist-item .rate-task-btn-msg {
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.15px;
    color: #fff;
    margin-left: 6px; }
  .orderlist-item .tip-link {
    display: inline-flex;
    margin-top: 9px;
    justify-content: center;
    align-items: center; }
  .orderlist-item .tip-icon {
    width: 18px;
    height: 18px; }
  .orderlist-item .tip-link-msg {
    font-size: 14px;
    font-weight: 900;
    color: #4ea558;
    margin-left: 6px;
    text-transform: uppercase; }
  .orderlist-item .tip-label {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.36px;
    color: #4f5269;
    margin-top: 6px; }
  .orderlist-item .order-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 121px;
    height: 44px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.15px; }
    .orderlist-item .order-label.in-progress {
      background-color: rgba(88, 158, 248, 0.12);
      color: #589ef8; }
    .orderlist-item .order-label.on-revision {
      background-color: rgba(242, 169, 70, 0.12);
      color: #f2a946; }
  .orderlist-item .rating-stars {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3px; }
    .orderlist-item .rating-stars .rating-star-item:not(:first-child) {
      margin-left: 5px; }
    .orderlist-item .rating-stars .star-empty-full-icon {
      display: block;
      width: 20px;
      height: 18px;
      color: #f2a946;
      fill: none; }
    .orderlist-item .rating-stars.mark1 .rating-star-item:nth-child(1) .star-empty-full-icon, .orderlist-item .rating-stars.mark2 .rating-star-item:nth-child(1) .star-empty-full-icon, .orderlist-item .rating-stars.mark3 .rating-star-item:nth-child(1) .star-empty-full-icon, .orderlist-item .rating-stars.mark4 .rating-star-item:nth-child(1) .star-empty-full-icon, .orderlist-item .rating-stars.mark5 .rating-star-item:nth-child(1) .star-empty-full-icon {
      fill: #f2a946; }
    .orderlist-item .rating-stars.mark2 .rating-star-item:nth-child(2) .star-empty-full-icon, .orderlist-item .rating-stars.mark3 .rating-star-item:nth-child(2) .star-empty-full-icon, .orderlist-item .rating-stars.mark4 .rating-star-item:nth-child(2) .star-empty-full-icon, .orderlist-item .rating-stars.mark5 .rating-star-item:nth-child(2) .star-empty-full-icon {
      fill: #f2a946; }
    .orderlist-item .rating-stars.mark3 .rating-star-item:nth-child(3) .star-empty-full-icon, .orderlist-item .rating-stars.mark4 .rating-star-item:nth-child(3) .star-empty-full-icon, .orderlist-item .rating-stars.mark5 .rating-star-item:nth-child(3) .star-empty-full-icon {
      fill: #f2a946; }
    .orderlist-item .rating-stars.mark4 .rating-star-item:nth-child(4) .star-empty-full-icon, .orderlist-item .rating-stars.mark5 .rating-star-item:nth-child(4) .star-empty-full-icon {
      fill: #f2a946; }
    .orderlist-item .rating-stars.mark5 .rating-star-item:nth-child(5) .star-empty-full-icon {
      fill: #f2a946; }
  .orderlist-item .change-rating-link {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #4ea558;
    margin-top: 12px; }
  .orderlist-item .edit-pay-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 208px;
    height: 46px;
    border-radius: 8px;
    border: solid 1px #51a45b;
    background-color: #51a45b;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.15px;
    color: #fff; }

.orderlist-item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .orderlist-item-wrapper.bottom {
    border-top: solid 1px rgba(151, 151, 151, 0.2);
    padding-top: 15px; }

.orderlist-item-subwrapper1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; }

.orderlist-item-subwrapper2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end; }

.order-list {
  width: 100%;
  margin-bottom: 20px;
  position: relative; }

.order-list-item {
  background: #fff;
  width: 100%;
  margin-top: 20px;
  border: 1px solid #cacbd3;
  box-shadow: 0 2px 4px 0 rgba(77, 81, 105, 0.14);
  border-radius: 5px;
  position: relative; }
  .order-list-item:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 6px;
    background: #9496a6;
    border-radius: 5px 0 0 5px; }
  .order-list-item.unpaid:before {
    background: #9496a6; }
  .order-list-item.on-revision:before,
  .order-list-item.on-revision .order-list-item-status, .order-list-item.revision-requested:before,
  .order-list-item.revision-requested .order-list-item-status {
    background: #ffa525; }
  .order-list-item.revision-sent:before,
  .order-list-item.revision-sent .order-list-item-status {
    background: #21d962; }
  .order-list-item.refunded:before,
  .order-list-item.refunded .order-list-item-status {
    background: #ff5d5d; }
  .order-list-item.nerd-found:before,
  .order-list-item.nerd-found .order-list-item-status {
    background: #44cbf1; }
  .order-list-item.in-progress:before,
  .order-list-item.in-progress .order-list-item-status, .order-list-item.quality-check:before,
  .order-list-item.quality-check .order-list-item-status, .order-list-item.final-touches:before,
  .order-list-item.final-touches .order-list-item-status {
    background: #1a99ff; }
  .order-list-item.completed:before,
  .order-list-item.completed .order-list-item-status, .order-list-item.sent:before,
  .order-list-item.sent .order-list-item-status {
    background: #21d862; }
  .order-list-item.refund-in-process:before,
  .order-list-item.refund-in-process .order-list-item-status {
    background: #9496a6; }

.order-list-item-left {
  width: 127px;
  height: 100%;
  padding-left: 25px;
  padding-top: 10px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0; }

.order-list-item-right {
  box-sizing: border-box;
  min-height: 213px;
  border-left: 1px solid #cacbd3;
  margin-left: 122px;
  padding: 14px 14px 70px 23px;
  position: relative; }

.order-list-item-deadline {
  position: relative; }

.order-list-item-deadline-date {
  font-size: 40px;
  line-height: 1.13;
  color: #4e526b; }

.order-list-item-deadline-month {
  font-size: 15px;
  font-weight: bold;
  color: #4e526b;
  text-transform: uppercase; }

.order-list-item-deadline-year {
  font-size: 14px;
  font-weight: bold;
  color: #4e526b; }

.order-list-item-deadline-expected {
  margin-top: 13px;
  position: relative; }
  .order-list-item-deadline-expected p {
    font-family: "Muli", sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    letter-spacing: -0.2px;
    color: #24a752; }
  .order-list-item-deadline-expected div {
    margin-top: 3px;
    font-family: "Muli", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.27;
    letter-spacing: -0.2px;
    color: #4e526b; }
    .order-list-item-deadline-expected div + div {
      margin-top: 0; }

.order-list-item-price {
  position: absolute;
  bottom: 16px;
  left: 25px;
  font-family: "Muli", sans-serif;
  font-size: 25px;
  font-weight: 600;
  line-height: 1.04;
  letter-spacing: -0.3px;
  color: #9496a6; }
  .order-list-item-price.tiny {
    font-size: 20px; }

.order-list-item-type {
  font-family: "Muli", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
  letter-spacing: 0.9px;
  color: #3591db;
  text-transform: uppercase; }

.order-list-item-number {
  font-family: "Muli", sans-serif;
  margin-top: 2px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: #9496a6; }

.order-list-item-title {
  height: 48px;
  margin-top: 13px;
  font-size: 19px;
  line-height: 1.26;
  letter-spacing: 0.3px;
  color: #4e526b;
  overflow: hidden; }

.order-list-item-size {
  display: inline-block;
  padding-top: 9px;
  font-family: "Muli", sans-serif;
  font-size: 15px;
  line-height: 19px;
  font-weight: bold;
  letter-spacing: -0.2px;
  color: #9496a6; }

.order-extra-services-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 16px 0 0 2px; }

.order-extra-services-item {
  display: inline-flex;
  align-items: center;
  margin-right: auto; }
  .order-extra-services-item:first-child {
    margin-top: 0; }
  .order-extra-services-item:not(:first-child) {
    margin-top: 8px; }
  .order-extra-services-item .check-added-icon, .order-extra-services-item .check-active-icon {
    display: block;
    width: 14px;
    height: 14px;
    fill: #ABACB8; }
  .order-extra-services-item .service-title {
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.23px;
    color: #4e526b;
    margin-left: 8px; }
    .order-extra-services-item .service-title.refunded {
      text-decoration: line-through;
      width: 98px;
      color: #abacb8;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
  .order-extra-services-item .refunded-label {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    font-family: "Muli", sans-serif;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.17px;
    color: #fff;
    border-radius: 2px;
    background-color: #fea0b2;
    padding: 1px 4px 0;
    margin: 0 -8px 0 3px; }

.order-list-item-button {
  background: #24a752;
  height: 40px;
  color: #fff;
  font-size: 14px;
  line-height: 40px;
  font-weight: bold;
  letter-spacing: 0.9px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;
  display: block;
  position: absolute;
  bottom: 10px;
  right: 23px;
  left: 23px; }

.order-list-item-status {
  min-width: 130px;
  height: 40px;
  font-size: 17px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 5px 0;
  padding: 0 10px; }

.order-list-additional-services-link {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px; }
  .order-list-additional-services-link .link-msg {
    font-family: "Muli", sans-serif;
    margin-left: 7px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.15px;
    color: #24a652; }
  .order-list-additional-services-link .plus-round-icon {
    display: block;
    width: 19px;
    height: 19px;
    color: #24a652; }

.rating-block {
  position: absolute;
  left: 23px;
  bottom: 16px; }

.rate-btn {
  display: inline-flex;
  width: 172px;
  height: 40px;
  border-radius: 4px;
  background-color: #24a652; }
  .rate-btn .inner-wrapper {
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    align-items: center; }
  .rate-btn .star-icon {
    display: block;
    width: 15px;
    height: 14px;
    fill: #fff;
    color: #fff; }
  .rate-btn .rate-btn-msg {
    font-family: "Roboto Slab", serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.9px;
    color: #fff;
    text-transform: uppercase;
    margin-left: 5px; }

.your-rating {
  display: flex;
  align-items: center; }
  .your-rating .your-rating-msg {
    font-family: "Muli", sans-serif;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.2px;
    color: #9496a6; }
  .your-rating .your-rating-stars {
    display: flex;
    justify-content: center;
    margin-left: 9px; }
  .your-rating .rate-star-item:not(:first-child) {
    margin-left: 2px; }
  .your-rating .star-icon {
    display: block;
    width: 18px;
    height: 18px;
    fill: #cacbd3;
    color: #cacbd3; }
  .your-rating.mark1 .rate-star-item:nth-child(1) .star-icon, .your-rating.mark2 .rate-star-item:nth-child(1) .star-icon, .your-rating.mark3 .rate-star-item:nth-child(1) .star-icon, .your-rating.mark4 .rate-star-item:nth-child(1) .star-icon, .your-rating.mark5 .rate-star-item:nth-child(1) .star-icon {
    fill: #ffa525;
    color: #ffa525; }
  .your-rating.mark2 .rate-star-item:nth-child(2) .star-icon, .your-rating.mark3 .rate-star-item:nth-child(2) .star-icon, .your-rating.mark4 .rate-star-item:nth-child(2) .star-icon, .your-rating.mark5 .rate-star-item:nth-child(2) .star-icon {
    fill: #ffa525;
    color: #ffa525; }
  .your-rating.mark3 .rate-star-item:nth-child(3) .star-icon, .your-rating.mark4 .rate-star-item:nth-child(3) .star-icon, .your-rating.mark5 .rate-star-item:nth-child(3) .star-icon {
    fill: #ffa525;
    color: #ffa525; }
  .your-rating.mark4 .rate-star-item:nth-child(4) .star-icon, .your-rating.mark5 .rate-star-item:nth-child(4) .star-icon {
    fill: #ffa525;
    color: #ffa525; }
  .your-rating.mark5 .rate-star-item:nth-child(5) .star-icon {
    fill: #ffa525;
    color: #ffa525; }
