.message-was-sent .img-wrap {
  padding-top: 68px; }
  .message-was-sent .img-wrap svg {
    width: 133px;
    height: 150px; }

.message-was-sent .title {
  margin: 40px 0 18px; }

.message-was-sent .subtitle {
  margin-bottom: 43px; }
