.email-sent-page .img-wrap {
  padding-top: 60px; }
  .email-sent-page .img-wrap svg {
    height: 88px;
    width: 114px; }

.email-sent-page .title {
  margin: 24px 0 10px; }

.email-sent-page .subtitle {
  margin-bottom: 30px; }
