@import 'https://fonts.googleapis.com/css?family=Muli:400,600,700,800,900|Roboto+Slab:400,700';
@font-face {
  font-family: 'Axiforma';
  src: url("/fonts/Axiforma-Regular.eot");
  src: local("Axiforma Regular"), local("Axiforma-Regular"), url("/fonts/Axiforma-Regular.woff") format("woff"), url("/fonts/Axiforma-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Axiforma';
  src: url("/fonts/Axiforma-Bold.eot");
  src: local("Axiforma Bold"), local("Axiforma-Bold"), url("/fonts/Axiforma-Bold.woff") format("woff"), url("/fonts/Axiforma-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@media only screen and (max-width: 900px) {
  .main-menu:not(.isOpen) .close-icon {
    display: none; }
  .main-menu:not(.isOpen) .burger-btn-msg {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.25px;
    color: #51a45b; }
  .main-menu:not(.isOpen) .main-menu-list-wrapper {
    display: none; }
  .main-menu.isOpen .burger-btn {
    background-color: #9496a6;
    box-shadow: inset 0 -1px 0 0 #cacbd3; }
  .main-menu.isOpen .close-icon {
    display: inline-block;
    color: #fff;
    width: 16px;
    height: 16px; }
  .main-menu.isOpen .burger-btn-msg {
    padding-right: 10px;
    color: #fff;
    font-size: 18px;
    vertical-align: 2px; }
  .main-menu.isOpen .main-menu-list-wrapper {
    display: block; } }

.burger-btn {
  display: block;
  position: absolute;
  height: 50px;
  padding: 0 16px;
  top: 0;
  left: 0; }
  @media only screen and (min-width: 901px) {
    .burger-btn {
      display: none; } }

.burger-btn-msg {
  text-transform: uppercase;
  font-family: "Muli", sans-serif;
  font-weight: bold;
  letter-spacing: 0.3px;
  vertical-align: 1px; }

@media only screen and (max-width: 900px) {
  .main-menu-list-wrapper {
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-188px);
    width: 290px;
    height: calc(100vh - 49px);
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden; } }

@media only screen and (max-width: 900px) {
  .main-menu-list {
    position: relative;
    padding: 20px 20px 100px;
    background-color: #fff;
    min-height: calc(100vh - 49px); } }

@media only screen and (min-width: 901px) {
  .main-menu-list {
    padding-top: 17px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center; } }

.main-menu-list .menu-link {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (max-width: 900px) {
    .main-menu-list .menu-link {
      font-size: 20px;
      letter-spacing: 0.1px;
      color: #24a652;
      padding: 20px 0; }
      .main-menu-list .menu-link:not(.no-sep) {
        border-top: dotted 1px #cacbd3; } }
  @media only screen and (min-width: 901px) {
    .main-menu-list .menu-link {
      font-size: 14px;
      line-height: 35px;
      letter-spacing: 0.1px;
      color: #24a652;
      margin-left: 30px; } }

.main-menu-list .login {
  color: #4e526b; }

.main-menu-list .task-num {
  color: #9496a6; }

.main-menu-list .menu-link-tag {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (min-width: 901px) {
    .main-menu-list .menu-link-tag {
      position: relative; } }

@media only screen and (min-width: 901px) {
  .main-menu-list .referral-link {
    order: -1; } }

.main-menu-list .menu-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Muli", sans-serif;
  letter-spacing: 0.06px;
  background-color: #ff7d2b;
  border-radius: 11.5px;
  text-transform: uppercase;
  color: #fff; }
  @media only screen and (max-width: 900px) {
    .main-menu-list .menu-tag {
      height: 22px;
      padding: 0 8px;
      font-size: 11px; } }
  @media only screen and (min-width: 901px) {
    .main-menu-list .menu-tag {
      height: 12px;
      font-size: 6px;
      padding: 1px 5px 0 6px;
      position: absolute;
      top: -1px;
      right: -15px; } }

.main-menu-list .hire-menu-link {
  border-radius: 100px;
  background-color: #24a652;
  color: #fff;
  padding: 0 24px; }
  @media only screen and (max-width: 900px) {
    .main-menu-list .hire-menu-link {
      display: none; } }

.main-menu-list .contact-menu-block {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 290px;
  height: 68px;
  overflow: hidden; }
  @media only screen and (min-width: 901px) {
    .main-menu-list .contact-menu-block {
      display: none; } }

.main-menu-list .contact-menu-msg {
  position: absolute;
  bottom: 14px;
  left: 106px;
  width: 160px;
  font-family: "Muli", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #9496a6; }

.main-menu-list .contact-us-link {
  display: block;
  font-weight: bold;
  color: #24a652; }

.main-menu-list .open-menu-img {
  width: 77px;
  height: 97px;
  position: absolute;
  bottom: -29px;
  left: 10px; }

.hire-menu-btn {
  display: block;
  border-radius: 100px;
  background-color: #24a652;
  text-align: center;
  font-size: 23px;
  color: #fff;
  line-height: 50px;
  margin-top: 20px; }
  @media only screen and (min-width: 901px) {
    .hire-menu-btn {
      display: none; } }

@media only screen and (max-width: 900px) {
  body.fixed {
    overflow: hidden; }
    body.fixed .overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 100; } }
