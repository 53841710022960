.footer .footer-body {
  background-color: #1c2036; }
  @media only screen and (max-width: 900px) {
    .footer .footer-body {
      background: url("./img/shape-footer.svg") no-repeat right top #1c2036; } }
  @media only screen and (max-width: 900px) {
    .footer .footer-body .wrapper {
      padding-top: 32px;
      padding-bottom: 40px;
      text-align: center; } }
  @media only screen and (min-width: 901px) {
    .footer .footer-body .wrapper {
      display: flex;
      height: 62px;
      justify-content: space-between;
      align-items: center; } }

@media only screen and (max-width: 900px) {
  .footer .footer-img-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px; } }

@media only screen and (min-width: 901px) {
  .footer .footer-img-block {
    display: inline-flex;
    justify-content: center;
    align-items: center; } }

@media only screen and (max-width: 900px) {
  .footer .footer__payment-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 8px; } }

@media only screen and (min-width: 901px) {
  .footer .footer__payment-list {
    display: inline-flex; } }

@media only screen and (max-width: 900px) {
  .footer .footer__payment-item {
    margin: 16px 8px 0 8px; } }

@media only screen and (min-width: 901px) {
  .footer .footer__payment-item:not(:first-child) {
    margin-left: 8px; } }

.footer .footer__payment-img {
  display: block; }
  @media only screen and (max-width: 900px) {
    .footer .footer__payment-img {
      height: 35px; } }
  @media only screen and (min-width: 901px) {
    .footer .footer__payment-img {
      height: 28px; } }

.footer .footer__badges-img {
  display: block; }
  @media only screen and (max-width: 900px) {
    .footer .footer__badges-img {
      height: 49px;
      order: -1; } }
  @media only screen and (min-width: 901px) {
    .footer .footer__badges-img {
      width: 130px;
      height: 33px;
      margin-left: 25px; } }

.footer .footer-nav {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 900px) {
    .footer .footer-nav {
      justify-content: center; } }
  @media only screen and (min-width: 901px) {
    .footer .footer-nav {
      justify-content: flex-start; } }

.footer .footer-nav-link {
  font-family: "Muli", sans-serif;
  display: block;
  color: #fff;
  font-size: 14px; }
  .footer .footer-nav-link:not(:first-child) {
    margin-left: 20px; }

@media only screen and (max-width: 900px) {
  .footer .contact-menu-block {
    display: none; } }

@media only screen and (min-width: 901px) {
  .footer .contact-menu-block {
    height: 43px;
    overflow: hidden; } }

.footer .contact-menu-msg {
  font-family: "Muli", sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #9496a6;
  padding: 16px 0 0 135px; }

.footer .contact-us-link {
  font-weight: bold;
  color: #24a652; }

.footer .open-menu-img {
  width: 48px;
  height: 60px;
  margin-left: 67px;
  float: left; }
