.not-found-page .img-wrap {
  padding-top: 61px; }
  .not-found-page .img-wrap svg {
    width: 134px;
    height: 90px; }

.not-found-page .title {
  margin: 25px 0 10px; }

.not-found-page .subtitle {
  margin-bottom: 25px; }

.not-found-page .link-block {
  width: 300px;
  margin: 0 auto; }
