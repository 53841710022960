@import 'https://fonts.googleapis.com/css?family=Muli:400,600,700,800,900|Roboto+Slab:400,700';
@font-face {
  font-family: 'Axiforma';
  src: url("/fonts/Axiforma-Regular.eot");
  src: local("Axiforma Regular"), local("Axiforma-Regular"), url("/fonts/Axiforma-Regular.woff") format("woff"), url("/fonts/Axiforma-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Axiforma';
  src: url("/fonts/Axiforma-Bold.eot");
  src: local("Axiforma Bold"), local("Axiforma-Bold"), url("/fonts/Axiforma-Bold.woff") format("woff"), url("/fonts/Axiforma-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

.select-deadline {
  padding: 0 38px; }

.rc-slider {
  width: 300px;
  height: 42px;
  padding: 0;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  margin-left: auto;
  margin-right: auto; }
  .rc-slider.order-block-item {
    margin-top: 30px; }

.rc-slider-step {
  background-image: linear-gradient(to right, #ff3634 0%, #ff3634 36%, #faa634 36%, #faa634 63%, #ffde2f 63%, #ffde2f 92%, #87c985 92%, #87c985 100%);
  height: 10px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0; }

.rc-slider-handle {
  background: url(/img/selector.svg) no-repeat;
  width: 42px;
  height: 42px;
  margin: 0;
  border: none;
  transform: translateX(-50%); }
  .rc-slider-handle:active {
    box-shadow: none;
    border: none; }

.rc-slider-rail,
.rc-slider-track,
.rc-slider-dot {
  display: none; }

.rc-slider-mark {
  top: -36px; }

.rc-slider-mark-text {
  display: none; }
  .rc-slider-mark-text:first-child {
    margin-left: 0 !important;
    text-align: left; }
  .rc-slider-mark-text:last-child {
    margin-left: 0 !important;
    left: auto !important;
    right: 0;
    text-align: right; }

.rc-slider-tooltip {
  display: block;
  left: 50% !important;
  transform: translate(-50%, 32px);
  padding: 0; }

.rc-slider-tooltip-arrow {
  display: none; }

.rc-slider-tooltip-inner {
  background: none;
  box-shadow: none;
  padding: 0;
  height: auto;
  font-family: "Muli", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: #24a752; }
