.drag-drop-zone-wrapper {
  position: relative; }
  @media only screen and (max-width: 900px) {
    .drag-drop-zone-wrapper {
      display: flex;
      flex: 1 1; } }

.drag-drop-zone {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  border-radius: 8px;
  border: dashed 3px #008c6a;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: 0.17px;
  text-align: center;
  color: rgba(2, 49, 53, 0.5); }
