.file-btn {
  height: 48px;
  border-radius: 4px;
  font-family: "Axiforma", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.15px;
  text-align: center;
  transition: opacity .5s ease;
  white-space: nowrap; }
  .file-btn:disabled {
    opacity: .5; }

.file-primary-btn {
  background-color: #007a5c;
  color: #fff; }

.file-secondary-btn {
  background-color: #d8dce0;
  color: #023135; }

.file-btn-block {
  display: flex;
  justify-content: center;
  align-items: center; }
  .file-btn-block .file-primary-btn {
    padding: 0 45px;
    margin-left: 12px; }
  .file-btn-block .file-secondary-btn {
    padding: 0 32px; }

.file-upload-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  border-radius: 4px;
  background-color: #007a5c;
  font-family: "Axiforma", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.15px;
  text-align: center;
  color: #fff;
  transition: opacity .5s ease; }
  @media only screen and (max-width: 900px) {
    .file-upload-btn {
      width: 100%; } }
  @media only screen and (min-width: 901px) {
    .file-upload-btn {
      width: 180px; } }
  .file-upload-btn input {
    display: none; }

.file-upload-page .logo, .file-send-page .logo, .file-failed-page .logo {
  display: block;
  flex-shrink: 0;
  fill: #000; }
  @media only screen and (max-width: 900px) {
    .file-upload-page .logo, .file-send-page .logo, .file-failed-page .logo {
      width: 60px;
      height: 20px;
      margin: 24px 0; } }
  @media only screen and (min-width: 901px) {
    .file-upload-page .logo, .file-send-page .logo, .file-failed-page .logo {
      width: 114px;
      height: 45px;
      margin: 32px 0; } }

.file-upload-page .info-line, .file-send-page .info-line, .file-failed-page .info-line {
  font-weight: bold;
  letter-spacing: 0.15px;
  text-align: center;
  color: #023135; }
  @media only screen and (max-width: 900px) {
    .file-upload-page .info-line, .file-send-page .info-line, .file-failed-page .info-line {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 28px; } }
  @media only screen and (min-width: 901px) {
    .file-upload-page .info-line, .file-send-page .info-line, .file-failed-page .info-line {
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 36px; } }

.file-upload-page {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b8ebec;
  align-items: center;
  font-family: "Axiforma", sans-serif;
  padding-bottom: 28px;
  overflow: auto; }
  .file-upload-page .file-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff; }
    @media only screen and (max-width: 900px) {
      .file-upload-page .file-frame {
        flex: 1 1;
        width: 335px; } }
    @media only screen and (min-width: 901px) {
      .file-upload-page .file-frame {
        width: 476px; } }
  @media only screen and (max-width: 900px) {
    .file-upload-page .file-frame1 {
      padding: 0 28px 16px; } }
  @media only screen and (min-width: 901px) {
    .file-upload-page .file-frame1 {
      height: 500px;
      padding: 40px 28px 0; } }
  @media only screen and (max-width: 900px) {
    .file-upload-page .file-frame2 {
      padding: 28px 28px 48px; } }
  @media only screen and (min-width: 901px) {
    .file-upload-page .file-frame2 {
      padding: 40px;
      margin-top: 8px; } }
  @media only screen and (max-width: 900px) {
    .file-upload-page .file-frame3 {
      padding: 28px 28px 40px; } }
  @media only screen and (min-width: 901px) {
    .file-upload-page .file-frame3 {
      padding: 40px 40px 60px;
      margin-top: 8px; } }
  .file-upload-page .stretch-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 900px) {
      .file-upload-page .stretch-element {
        flex: 1; } }
  .file-upload-page .file-img1 {
    display: block; }
    @media only screen and (max-width: 900px) {
      .file-upload-page .file-img1 {
        width: 190px;
        height: 214px;
        margin: 28px 0; } }
    @media only screen and (min-width: 901px) {
      .file-upload-page .file-img1 {
        width: 200px;
        height: 224px; } }
  .file-upload-page .file-img2 {
    display: block;
    margin: 28px 0; }
    @media only screen and (max-width: 900px) {
      .file-upload-page .file-img2 {
        width: 218.4px;
        height: 133.4px; } }
    @media only screen and (min-width: 901px) {
      .file-upload-page .file-img2 {
        width: 230px;
        height: 141px; } }
  .file-upload-page .file-img3 {
    display: block;
    margin: 40px 0;
    width: 206.9px;
    height: 161px; }
  .file-upload-page .file-img4 {
    display: block;
    margin-top: 32px;
    width: 247px;
    height: 146px; }
  .file-upload-page .info-line2 {
    font-size: 18px;
    line-height: 1.56;
    letter-spacing: 0.17px;
    color: #023135;
    margin-top: 40px; }
  .file-upload-page .or-line {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: 0.15px;
    color: #023135;
    margin: 20px 0; }
    .file-upload-page .or-line:before, .file-upload-page .or-line:after {
      content: '';
      display: block;
      width: 90px;
      height: 1px;
      background-color: rgba(193, 199, 205, 0.5);
      margin: 0 8px; }
  @media only screen and (min-width: 901px) {
    .file-upload-page .info-line3 {
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: 0.15px;
      color: #023135;
      text-align: center; } }
  .file-upload-page .frame-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.15px;
    text-align: center;
    color: #023135; }
  .file-upload-page .frame-subtitle {
    font-size: 18px;
    line-height: 1.56;
    letter-spacing: 0.17px;
    text-align: center;
    color: #023135;
    margin-top: 4px; }
  .file-upload-page .file-link {
    font-family: "Axiforma", sans-serif;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.15px;
    text-align: center;
    color: #023135;
    text-decoration: underline; }

.file-failed-page {
  font-family: "Axiforma", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media only screen and (max-width: 900px) {
    .file-failed-page {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff; } }
  @media only screen and (min-width: 901px) {
    .file-failed-page {
      min-height: 100vh;
      background-color: #b8ebec;
      padding-bottom: 35px; } }

.file-send-page {
  font-family: "Axiforma", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media only screen and (max-width: 900px) {
    .file-send-page {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff; } }
  @media only screen and (min-width: 901px) {
    .file-send-page {
      min-height: 100vh;
      background-color: #b8ebec;
      padding-bottom: 35px; } }
  @media only screen and (max-width: 900px) {
    .file-send-page .drag-drop-zone-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      min-width: 375px;
      max-width: 450px;
      margin-left: auto;
      margin-right: auto; } }
  .file-send-page .file-send-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto; }
    @media only screen and (max-width: 900px) {
      .file-send-page .file-send-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-width: 375px;
        max-width: 450px; } }
    @media only screen and (min-width: 901px) {
      .file-send-page .file-send-wrapper {
        width: 476px;
        border-radius: 12px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff; } }
  .file-send-page .top-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 20px;
    border-bottom: 1px solid #edf0f2; }
    @media only screen and (max-width: 900px) {
      .file-send-page .top-part {
        padding: 7px 20px; } }
    @media only screen and (min-width: 901px) {
      .file-send-page .top-part {
        padding: 16px 20px 12px; } }
  .file-send-page .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.56;
    letter-spacing: 0.17px;
    color: #023135; }
  .file-send-page .file-count {
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.15px;
    color: #637381; }
  .file-send-page .upload-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    background-color: #007a5c;
    font-size: 16px;
    color: #fff; }
    .file-send-page .upload-btn input {
      display: none; }
  .file-send-page .middle-part {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto; }
  @media only screen and (max-width: 900px) {
    .file-send-page .bottom-part {
      padding: 12px 20px; } }
  @media only screen and (min-width: 901px) {
    .file-send-page .bottom-part {
      display: flex;
      justify-content: flex-end;
      padding: 8px 20px 20px; } }
  .file-send-page .file-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid #edf0f2; }
    .file-send-page .file-list-item.error {
      background-color: #f2dfdf; }
  .file-send-page .preview-icon {
    display: block;
    width: 30px;
    height: 40px; }
  .file-send-page .file-info {
    flex: 1;
    padding: 0 28px; }
  .file-send-page .file-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.15px;
    color: #023135; }
  .file-send-page .file-size {
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.15px;
    color: #637381; }
  .file-send-page .error-line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2px; }
  .file-send-page .error-icon {
    display: block;
    width: 15px;
    height: 15px; }
  .file-send-page .error-message {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .15px;
    font-weight: bold;
    color: #e44949;
    padding: 3px 0 0 5px; }
  .file-send-page .name {
    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .file-send-page .file-delete-icon {
    display: block;
    width: 24px;
    height: 24px;
    fill: #b1b9c0; }
  .file-send-page .reload-icon {
    display: block;
    width: 24px;
    height: 24px; }
  .file-send-page .description-block {
    padding: 20px 20px 12px; }
  .file-send-page .description-title {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.15px;
    color: #023135; }
    .file-send-page .description-title .strong {
      font-weight: bold; }
  .file-send-page .description-field {
    display: block;
    width: 100%;
    height: 120px;
    margin-top: 12px;
    border-radius: 4px;
    border: solid 1px #c4cdd5;
    background-color: #fff;
    resize: none;
    padding: 10px;
    font-size: 16px;
    color: #023135; }
