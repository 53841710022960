@import 'https://fonts.googleapis.com/css?family=Muli:400,600,700,800,900|Roboto+Slab:400,700';
@font-face {
  font-family: 'Axiforma';
  src: url("/fonts/Axiforma-Regular.eot");
  src: local("Axiforma Regular"), local("Axiforma-Regular"), url("/fonts/Axiforma-Regular.woff") format("woff"), url("/fonts/Axiforma-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Axiforma';
  src: url("/fonts/Axiforma-Bold.eot");
  src: local("Axiforma Bold"), local("Axiforma-Bold"), url("/fonts/Axiforma-Bold.woff") format("woff"), url("/fonts/Axiforma-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

.discount-block .coupon-btn {
  display: flex;
  align-items: center;
  margin: 27px auto; }

.discount-block .coupon-icon {
  width: 16px;
  height: 15px;
  fill: #FFC200; }

.discount-block .coupon-btn-msg {
  font-family: "Muli", sans-serif;
  font-size: 17px;
  font-weight: 800;
  color: #1a99ff;
  margin-left: 9px; }

.discount-block .promo-enter-block {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 20px; }

.discount-block .promo-input-wrapper {
  width: 229px;
  position: relative; }

.discount-block .promo-input {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #9496a6;
  background-color: #fff;
  padding: 0 10px;
  font-family: "Muli", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #4e526b;
  text-transform: uppercase; }
  .discount-block .promo-input::placeholder {
    color: #9496a6;
    text-transform: none; }
  .discount-block .promo-input.error {
    border-color: #ff3333; }
  .discount-block .promo-input:disabled {
    color: #545454;
    background-color: #ebebe4;
    cursor: default; }

.discount-block .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px; }
  .discount-block .close-btn .svg-icon {
    width: 16px;
    height: 16px;
    fill: #F33; }

.discount-block .apply-btn {
  width: 106px;
  height: 40px;
  border-radius: 4px;
  background-color: #1a99ff;
  font-family: "Muli", sans-serif;
  font-size: 17px;
  font-weight: 800;
  letter-spacing: 0.3px;
  text-align: center;
  color: #fff; }

.discount-block .applied-code-block {
  margin: 15px 0; }

.discount-block .applied-code-table {
  width: 260px;
  margin: 0 auto; }
  .discount-block .applied-code-table td {
    height: 30px; }
  .discount-block .applied-code-table .cell-label {
    text-align: left;
    font-family: "Muli", sans-serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 1.94;
    color: #9496a6;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .discount-block .applied-code-table .cell-price {
    font-family: "Muli", sans-serif;
    font-size: 17px;
    font-weight: bold;
    line-height: 1.65;
    color: #4e526b; }
  .discount-block .applied-code-table .highlighted {
    color: #24a652; }
  .discount-block .applied-code-table .strong {
    font-weight: 800; }
  .discount-block .applied-code-table .final {
    font-weight: 900; }

.discount-block .applied-code-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 345px;
  height: 45px;
  border-radius: 5px;
  background-color: #f9f9fa;
  padding: 0 15px;
  margin: 15px auto 0; }
  .discount-block .applied-code-field .applied-code-line {
    font-family: "Muli", sans-serif;
    font-size: 16px;
    line-height: 2.19;
    color: #6c6f84;
    white-space: nowrap;
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis; }
  .discount-block .applied-code-field .applied-code {
    font-weight: 800;
    color: #4e526b; }
  .discount-block .applied-code-field .divider {
    width: 1px;
    height: 22px;
    background-color: #dadadf; }
  .discount-block .applied-code-field .remove-btn {
    display: flex;
    align-items: center; }
  .discount-block .applied-code-field .trash-icon {
    width: 14px;
    height: 14px;
    color: #ff5d5d; }
  .discount-block .applied-code-field .remove-btn-msg {
    font-family: "Muli", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 2.19;
    letter-spacing: -0.2px;
    color: #ff5d5d;
    margin-left: 5px; }

.discount-block .discount-error-msg {
  margin: -10px 0 25px;
  font-family: "Muli", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.44;
  color: #ff5d5d; }

.discount-block .discount-error-link {
  text-decoration: underline;
  color: #ff5d5d; }
