.buttons-group {
  position: relative; }
  @media only screen and (max-width: 900px) {
    .buttons-group.filters {
      padding: 12px 0;
      box-shadow: 0 2px 4px 0 rgba(121, 124, 144, 0.15);
      background-color: #fff;
      position: fixed;
      left: 0;
      top: 50px;
      width: 100%;
      z-index: 1; } }
  .buttons-group.filters .filter-list {
    display: flex;
    border-radius: 8px;
    overflow: hidden; }
    @media only screen and (max-width: 900px) {
      .buttons-group.filters .filter-list {
        width: 343px;
        margin: 0 auto; } }
  .buttons-group.filters .filter-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f1f5;
    flex-basis: 100%;
    font-family: "Muli", sans-serif;
    font-size: 16px;
    line-height: 35px;
    font-weight: 600;
    letter-spacing: .09px;
    text-align: center;
    color: #4f5269;
    text-transform: capitalize;
    cursor: pointer;
    transition: background 300ms linear;
    will-change: background; }
    .buttons-group.filters .filter-item.active {
      background-color: #4f5269;
      color: #fff; }
